import { Component, ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from './components/Layout'
import NotFound from './pages/NotFound'

type AppError = Error & {
  status?: number
}

interface ErrorBoundaryState {
  error: AppError | null
}

function ResetErrorOnLocationChange({ onReset }: { onReset(): void }) {
  const location = useLocation()
  const prevLocationRef = useRef(location)
  useEffect(() => {
    if (location !== prevLocationRef.current) {
      prevLocationRef.current = location
      onReset()
    }
  }, [location, onReset])
  return null
}

export default class ErrorBoundary extends Component<{ children: ReactNode }> {
  state: ErrorBoundaryState = {
    error: null,
  }

  static getDerivedStateFromError(error: AppError) {
    return { error }
  }

  componentDidCatch(error: AppError, errorInfo: any) {
    console.error('Catch da error', error, errorInfo)
  }

  onReset = () => {
    this.setState({ error: null })
  }

  render() {
    const { children } = this.props
    const { error } = this.state
    if (error) {
      if (error.status === 404) {
        return (
          <Layout>
            <ResetErrorOnLocationChange onReset={this.onReset} />
            <NotFound />
          </Layout>
        )
      }
      return (
        <div className="text-center mt-md-4">
          <h1>
            Something went wrong <small>😭</small>
          </h1>
        </div>
      )
    }

    return children
  }
}
