import { ReactNode } from 'react'
import Footer from './Footer'
import Topbar from './Topbar'

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <Topbar />
      {children}
      <Footer />
    </>
  )
}
