import { useQuery } from 'react-query'
import { Project } from '../../types'
import { fireDocToPlainObj } from './utils'

const FIRESTORE_URL =
  process.env.REACT_APP_FIREBASE_EMULATED === 'yes'
    ? 'http://localhost:8080'
    : 'https://firestore.googleapis.com'

const API_URL = `${FIRESTORE_URL}/v1/projects/${process.env.REACT_APP_FIREBASE_PROJECT}/databases/(default)/documents`

export function useProject(slug: string): Project {
  const { data } = useQuery(['project', slug], () =>
    fetch(`${API_URL}/projects/${slug}`)
      .then((r) => (r.ok ? r.json() : Promise.reject(r)))
      .then((doc) => fireDocToPlainObj(doc))
  )
  return data
}

export function useProjects(): Project[] {
  const { data } = useQuery('projects', () =>
    fetch(`${API_URL}:runQuery`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        structuredQuery: {
          from: [{ collectionId: 'projects' }],
          orderBy: [
            {
              field: {
                fieldPath: 'anno',
              },
              direction: 'DESCENDING',
            },
          ],
          // where: {
          //   fieldFilter: {
          //     field: {
          //       fieldPath: 'isHomePage',
          //     },
          //     op: 'EQUAL',
          //     value: {
          //       booleanValue: true,
          //     },
          //   },
          // },
        },
      }),
    })
      .then((r) => (r.ok ? r.json() : Promise.reject(r)))
      .then((data) => data.map((o: any) => fireDocToPlainObj(o.document)))
  )
  return data
}
