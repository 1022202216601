import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Contact from './pages/Contact'
import ErrorBoundary from './ErrorBoundary'
import RouterLayout from './components/RouterLayout'
import About from './pages/About'
import ProjectDetail from './pages/ProjectDetail'
import Projects from './pages/Projects'
import Analytics from './components/Analytics'

export default function App() {
  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<RouterLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/:slug" element={<ProjectDetail />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Analytics />
    </ErrorBoundary>
  )
}
