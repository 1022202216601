import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useUniversalOrigin } from '../../hooks/page'
import { useProjects } from '../../hooks/projects'

export default function Home() {
  const projects = useProjects()
  const origin = useUniversalOrigin()
  return (
    <div className="page">
      <Helmet defer={false}>
        {projects.length > 0 && (
          <meta property="og:image" content={`${origin}/${projects[0].image}`} />
        )}
      </Helmet>
      <div>
        <div className="container-home">
          <div className="text-center text-home">
            <h1>Chromatic layers on walls</h1>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            {projects.map((project) => (
              <div className="col-md-3 mb-4 project" key={project.slug}>
                <Link to={`/projects/${project.slug}`}>
                  <img
                    alt={project.title}
                    className="img-fluid image"
                    src={'/' + project.imageThumb}
                  />
                  <div className="middle">
                    <div className="text">{project.title}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
