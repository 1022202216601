import { useContext } from 'react'
import { StaticContext } from '../../staticContext'

export default function NotFound() {
  const staticCtx = useContext(StaticContext)
  if (staticCtx) {
    staticCtx.status = 404
  }
  return (
    <div className="page text-center" style={{ height: 400 }}>
      <h1>404 Page Not Found</h1>
    </div>
  )
}
