import { useContext } from 'react'
import { StaticContext } from '../staticContext'

/**
 *  On server side use server origin on browser use window
 */
export function useUniversalOrigin() {
  const ctx = useContext(StaticContext)
  if (ctx) {
    return ctx.origin
  }
  if (typeof window !== 'undefined') {
    return window.location.origin
  }
  return ''
}
