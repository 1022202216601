import uniqBy from 'lodash/uniqBy'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import IsoTopeGrid from 'react-isotope'
import { useProjects } from '../../hooks/projects'
import { Project } from '../../../types'
import classNames from 'classnames'
import { useUniversalOrigin } from '../../hooks/page'

const CARD_WIDTH = 300
const CARD_HEIGHT = 300

const CARD_W_GUTTER = 20
const CARD_H_GUTTER = 20

function ProjectCard({ project }: { project: Project }) {
  return (
    <div className="project">
      <Link to={`/projects/${project.slug}`}>
        <img
          alt={project.title}
          className="img-fluid image"
          src={'/' + project.imageThumb}
        />
        <div className="middle">
          <div className="text">{project.title}</div>
        </div>
      </Link>
    </div>
  )
}

export default function Projects() {
  const projects = useProjects()
  const origin = useUniversalOrigin()

  const categories = useMemo(() => {
    return uniqBy(projects, 'categoria').map((p) => p.categoria)
  }, [projects])

  const [filters, setFilters] = useState(() =>
    categories.map((cat) => ({
      label: cat,
      isChecked: true,
    }))
  )

  const projectsCards = useMemo(
    () =>
      projects.map((project) => ({
        ...project,
        id: project.slug,
        filter: [project.categoria],
      })),
    [projects]
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const [parentWidth, setParentWidth] = useState(0)

  useEffect(() => {
    const container = containerRef.current
    function handleResize() {
      if (container) {
        const { width } = container.getBoundingClientRect()
        setParentWidth(width)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const nCols =
    parentWidth > 0
      ? Math.max(Math.round(parentWidth / (CARD_WIDTH + CARD_W_GUTTER)), 1)
      : 0
  const width = (nCols - 1) * (CARD_WIDTH + CARD_W_GUTTER) + CARD_WIDTH

  const activeFlatFilters = useMemo(
    () => filters.filter((f) => f.isChecked).map((f) => f.label),
    [filters]
  )
  const nActive = useMemo(() => {
    return projects.filter((project) => {
      return activeFlatFilters.includes(project.categoria)
    }).length
  }, [activeFlatFilters, projects])
  const nRows = nCols === 0 ? 0 : Math.ceil(nActive / nCols)
  const height = nRows * (CARD_HEIGHT + CARD_H_GUTTER)

  return (
    <div className="page">
      <Helmet defer={false}>
        <title>Veronellie - Chromatic layers on walls - Works</title>
        {projects.length > 0 && (
          <meta property="og:image" content={`${origin}/${projects[0].image}`} />
        )}
      </Helmet>
      <div>
        <div className="container-fluid">
          <div className="d-flex mb-4 justify-content-center align-items-center">
            {filters.map((filter, i) => {
              const enabled = filter.isChecked
                ? activeFlatFilters.length > 1
                : true
              return (
                <div
                  onClick={() => {
                    setFilters(
                      filters.map((f) =>
                        filter.label === f.label
                          ? { ...f, isChecked: !f.isChecked }
                          : f
                      )
                    )
                  }}
                  key={i}
                  className={classNames('filter me-3 ', {
                    'fw-bold': filter.isChecked,
                    'cursor-pointer': enabled,
                    'no-pointer': !enabled,
                  })}
                >
                  {filter.label}
                </div>
              )
            })}
          </div>
          <div
            className="w-100 d-flex justify-content-center"
            ref={containerRef}
          >
            <div
              style={{ width, height }}
              className={classNames('stupid-isotope', {
                'd-none': nCols === 0,
              })}
            >
              <IsoTopeGrid
                key={nCols}
                gridLayout={projectsCards as any[]}
                noOfCols={nCols}
                unitWidth={300}
                unitHeight={300}
                filters={filters}
              >
                {projectsCards.map((project) => (
                  <div key={project.id}>
                    <ProjectCard project={project} />
                  </div>
                ))}
              </IsoTopeGrid>
              )
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
