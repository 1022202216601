import Helmet from 'react-helmet'
import about from '../../assets/about.jpg'

export default function About() {
  return (
    <div className="page">
      <Helmet defer={false}>
        <title>Veronellie - Chromatic layers on walls - About</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 ps-md-5 pt-md-5 col-md-6">
            <p>I am a visual artist, mostly muralist and painter.</p>
            <p>
              My work is based on the creation of chromatic scales that will
              eventually shape the surface of the wall or the canvas.
            </p>
            <p>
              I usually work with transparency, overlapping layers and light and
              shadow effects that all converge into linear geometric
              compositions.
            </p>
          </div>
          <div className="col-12 col-md-6 pe-md-5 mt-4 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
            <img
              src={about}
              alt="Elisa Veronelli"
              width={300}
              className="mt-md-1"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
