import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'

const gaCode = process.env.REACT_APP_GA_CODE

let accepted = getCookieConsentValue() === 'true'

if (accepted) {
  initAnalytics()
}

export function initAnalytics() {
  if (gaCode) {
    ReactGA.initialize(gaCode)
  }
}

export function trackVisit(page: string) {
  if (gaCode && accepted) {
    ReactGA.send({ hitType: 'pageview', page })
  }
}

export default function Analytics() {
  const location = useLocation()
  const page = location.pathname + location.search

  useEffect(() => {
    trackVisit(page)
  }, [page])

  return (
    <CookieConsent
      style={{ background: '#fff', border: '1px solid black', color: 'black' }}
      buttonStyle={{ background: '#D191BB', color: 'white' }}
      onAccept={() => {
        accepted = true
        initAnalytics()
        trackVisit(page)
      }}
    >
      This website uses Google Analytics to anonymously collect browsing data.
    </CookieConsent>
  )
}
