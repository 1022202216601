import { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'
import Layout from './Layout'
import aboutImage from '../assets/about.jpg'
import { useUniversalOrigin } from '../hooks/page'

export default function RouterLayout() {
  const origin = useUniversalOrigin()
  return (
    <Layout>
      {/*
        NOTE:
        Defaults meta tags for all the website
        Can be ovewritten...
      */}
      <Helmet defer={false}>
        <title>Veronellie - Chromatic layers on walls</title>
        <meta
          name="description"
          content="I am a visual artist, mostly muralist and painter, My work is based on the creation of chromatic scales that will eventually shape the surface of the wall or the canvas. I usually work with transparency, overlapping layers and light and shadow effects that all converge into linear geometric compositions."
        />
        <meta
          name="keywords"
          content="everonelli, chromatic layers, layers, interior design, urban walls"
        />
        <meta name="author" content="Skaffo and Giova" />
        <meta
          property="og:title"
          content="VeronelliE - Chromatic layers on walls"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={origin + aboutImage} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Suspense fallback={<div className="page loader">Loading...</div>}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}
