export default function Footer() {
  return (
    <footer className="container-fluid">
      <div className="row">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div>Veronellie</div>
          <div className="mt-2 mb-2">
            <a
              href="https://www.instagram.com/veronellie/"
              rel="noreferrer"
              target={'_blank'}
            >
              <i className="bi text-secondary  bi-instagram"></i>
            </a>
          </div>
          <div>
            © Elisa Veronelli {new Date().getFullYear()} - All Rights Reserved
          </div>
          <small>
            Powered by{' '}
            <a
              href="https://github.com/skaffo"
              rel="noreferrer"
              className="no-link"
              target={'_blank'}
            >
              Skaffo
            </a>{' '}
            {'&'}{' '}
            <a
              href="https://github.com/gffuma"
              rel="noreferrer"
              className="no-link"
              target={'_blank'}
            >
              Giova
            </a>
            &nbsp;🖤
          </small>
        </div>
      </div>
    </footer>
  )
}
