import { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Helmet } from 'react-helmet'
import { Alert } from 'reactstrap'

function sendContact(data: any) {
  return fetch(`/x/send-contact`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json; charset=utf-8;',
    },
    body: JSON.stringify(data),
  })
}

export default function Contact() {
  const [captcha, setCaptcha] = useState<string | null>(null)
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const canSubmit = message !== '' && name !== '' && captcha !== null

  return (
    <div>
      <Helmet defer={false}>
        <title>Veronellie - Chromatic layers on walls - Contacts</title>
      </Helmet>
      <div className="page contact-page d-flex flex-column align-items-center container-fluid">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h2 className="text-center mt-3 mb-5">
              Do you want more information on my projects? Send a message.
            </h2>
            {messageSent && (
              <Alert color="success">
                Thanks for your message. I will reach you.
              </Alert>
            )}
            {!messageSent && (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  setSending(true)
                  sendContact({
                    message,
                    name,
                    email,
                    captcha,
                  })
                    .then((r) => {
                      setMessageSent(true)
                      setSending(false)
                    })
                    .catch((err) => {
                      console.error('Error in contact', err)
                      setSending(false)
                    })
                }}
              >
                <label htmlFor="nome">Name</label>
                <input
                  type={'text'}
                  name="nome"
                  className="form-control mb-3"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="nome">E-mail</label>
                <input
                  type={'email'}
                  name="email"
                  className="form-control mb-3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  className="form-control mb-3"
                  value={message}
                  rows={6}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <ReCAPTCHA
                  className="mt-2"
                  sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY!}
                  onChange={setCaptcha}
                />
                <div className=" d-flex justify-content-end">
                  <button
                    className="btn btn-outline-primary my-2"
                    disabled={!canSubmit || sending}
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
