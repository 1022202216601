import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { useUniversalOrigin } from '../../hooks/page'
import { useProject } from '../../hooks/projects'

export default function ProjectDetail() {
  const { slug } = useParams()
  const project = useProject(slug!)
  const navigate = useNavigate()
  const origin = useUniversalOrigin()

  return (
    <div>
      <Helmet defer={false}>
        <title>{`Veronellie - ${project.title}`}</title>
        <meta property="og:image" content={`${origin}/${project.image}`} />
      </Helmet>
      <div className="page-project-detail d-flex flex-column align-items-center container-fluid">
        <h1 className='font-weight-400'>{project.title}</h1>
        <h5>
          {project.luogo} - {project.anno}
        </h5>
        <div className="d-flex align-items-center justify-content-center mb-3">
          {project.webSite && (
            <a
              href={project.webSite}
              target="_blank"
              rel="noreferrer"
              className="btn btn-sm btn-light border mt-1"
            >
              Press
            </a>
          )}
        </div>
        <div className="mt-5">
          <div>
            <img
              alt={project.title}
              src={'/' + project.image}
              className={'img-detail-project'}
            />
          </div>
          {project.galleryImages.length > 0 &&
            project.galleryImages.map((galleryImage, i) => (
              <div className="mt-3" key={galleryImage.id}>
                <img
                  src={'/' + galleryImage.image}
                  className={'img-detail-project'}
                  alt={`Gallery item ${i}`}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="go-back">
        <h3 onClick={() => navigate(-1)}>
          <i className="fa fa-2x bi-arrow-90deg-left" />
        </h3>
      </div>
    </div>
  )
}
