import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './index.css'
import { StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { QueryClient, hydrate, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

console.log(
  `%cRunning VeronelliE 🚀 %c${process.env.REACT_APP_SITE_VERSION || 'dev'}`,
  'color:#FFD700; background: #3c1179;',
  'color:#00CD00;font-weight: bold;background: #3c1179;'
)

declare global {
  interface Window {
    __INITIAL_DATA__: any
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      staleTime: Infinity,
      suspense: true,
      retry: false,
    },
  },
})

hydrate(queryClient, window.__INITIAL_DATA__)
delete window.__INITIAL_DATA__

hydrateRoot(
  document.getElementById('root')!,
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
)
