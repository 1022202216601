function fireFieldToValue(field: any) {
  if (field.stringValue !== undefined) {
    return String(field.stringValue)
  } else if (field.integerValue !== undefined) {
    return Number(field.integerValue)
  } else if (field.booleanValue !== undefined) {
    return Boolean(field.booleanValue)
  } else if (field.timestampValue !== undefined) {
    // NOTE: Returing serializable stuff for SSR
    return String(field.timestampValue)
  } else if (field.arrayValue !== undefined) {
    return (field.arrayValue.values ?? []).map(fireFieldToValue)
  } else if (field.mapValue !== undefined) {
    return Object.keys(field.mapValue.fields ?? {}).reduce((deMap, key) => {
      deMap[key] = fireFieldToValue(field.mapValue.fields[key])
      return deMap
    }, {} as any)
  }
  return null
}

export function fireDocToPlainObj(doc: any) {
  return Object.keys(doc.fields).reduce((obj, name) => {
    obj[name] = fireFieldToValue(doc.fields[name])
    return obj
  }, {} as any)
}
