import { useState } from 'react'
import { Link } from 'react-router-dom'
import burger from '../assets/burger.svg'

export default function Topbar() {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <header>
        <div>
          <Link to="/" className="title-h2">
            <h2>Veronellie</h2>
          </Link>
        </div>
        <div className="menu">
          <div>
            <Link to="/">Home</Link>
          </div>
          <div>
            <Link to="/about">About</Link>
          </div>
          <div>
            <Link to="/projects">Works</Link>
          </div>
          <div>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div
          className="menu-mobile pointer"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <i className="bi bi-2x bi-x-lg"></i>
          ) : (
            <img src={burger} alt="Menu" />
          )}
        </div>
      </header>
      {menuOpen && (
        <div className="menu-mobile-open">
          <div>
            <Link onClick={() => setMenuOpen(false)} to="/">
              Home
            </Link>
          </div>
          <div>
            <Link onClick={() => setMenuOpen(false)} to="/about">
              About
            </Link>
          </div>
          <div>
            <Link onClick={() => setMenuOpen(false)} to="/projects">
              Works
            </Link>
          </div>
          <div>
            <Link onClick={() => setMenuOpen(false)} to="/contact">
              Contact
            </Link>
          </div>
        </div>
      )}
    </>
  )
}
